<template>
  <div>
    <div class="" v-show="sectionView == 'list'">
      <v-app-bar flat color="transparent" class="main-appbar">
        <v-toolbar-title class="headline font-weight-normal ml-0"
          >Content Resources</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="addResource()">
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path
              d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"
            />
          </svg>
        </v-btn>
      </v-app-bar>
      <div class="ml-4">
        <v-simple-table style="">
          <thead>
            <tr>
              <th width="20%" class="text-left pa-0">Resource URL</th>
              <th width="20%" class="text-left pa-0">Resource Title</th>
              <th width="20%" class="text-left">Description</th>
              <th width="20%" class="text-left">Sensory Inputs</th>
              <th width="20%" class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="resource in resources" v-bind:key="resource.resource_id">
              <td>{{ resource.url }}</td>
              <td>{{ resource.title }}</td>
              <td>{{ resource.description }}</td>
              <td>
                <span
                  style="background-color: #E5E5E5; margin-right: 8px; padding:8px"
                  v-for="trigger in resource.triggers"
                  v-bind:key="trigger.trigger_category_id"
                  >{{ trigger.trigger_category }}</span
                >
              </td>
              <td>
                <v-btn
                  color="error"
                  :loading="deleting"
                  @click="deleteResource(resource.resource_id)"
                >
                  Delete
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div v-show="sectionView == 'edit'">
      <v-app-bar flat color="transparent" class="main-appbar">
        <v-toolbar-title class="headline font-weight-normal"
          >{{ resource.map_id ? 'Edit' : 'Add' }} Content Resources
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="back()">
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path
              d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"
            />
          </svg>
        </v-btn>
      </v-app-bar>
      <v-alert v-show="error" color="error" text="" icon="$error" title="">
        {{ error }}
      </v-alert>
      <v-container class="bg-surface-variant">
        <v-row no-gutters>
          <!--<div>{{ resource }}</div>-->
          <v-col>
            <div class="pb-0">
              <label class="text-field-label ml-1">URL</label>
              <v-input>
                <v-text-field
                  flat
                  solo
                  required
                  class="mt-8"
                  v-model="resource.url"
                ></v-text-field>
                <v-btn
                  style="border:1px green solid"
                  class="mt-0"
                  color="success"
                  @click="populateResource()"
                  :loading="populating"
                >
                  Populate
                </v-btn>
              </v-input>
            </div>
            <div style="">
              <label class="text-field-label ml-1">Resource Title</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2 pb-0"
                v-model="resource.title"
              >
              </v-text-field>
            </div>
            <div>
              <label class="text-field-label ml-1">Description</label>
              <v-textarea
                flat
                solo
                required
                class="mt-2"
                v-model="resource.description"
              ></v-textarea>
            </div>
            <div style="">
              <label class="text-field-label ml-1">Thumbnail</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2 pb-0"
                v-model="resource.image"
              >
              </v-text-field>
              <div v-if="resource.image">
                <img :src="resource.image" style="height:90px" />
              </div>
            </div>
          </v-col>
          <v-col class="ml-12">
            <div>
              <label class="text-field-label ml-1">Sensory Inputs</label>
              <v-switch
                v-for="triggerCategory in triggerCategories"
                v-bind:key="triggerCategory.trigger_category_id"
                v-model="selectedTriggers"
                hide-details
                inset
                :value="triggerCategory.trigger_category_id"
                :label="triggerCategory.trigger_category"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <div class="pt-4 pl-4">
        <v-btn color="info" @click="saveResource()" :loading="saving">
          Save
        </v-btn>
        <v-btn color="default" @click="back()">Cancel</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import Api from '@/services/api'

export default {
  name: 'ContentResources',

  data() {
    return {
      resources: [],
      resource: {
        map_id: null,
        title: '',
        url: '',
        description: '',
        image: '',
        triggers: []
      },
      blankResource: {},
      sectionView: 'list',
      selectedTriggers: [],
      saving: false,
      tab: 0,
      populating: false,
      deleting: false,
      triggerCategories: [],
      error: null
    }
  },
  computed: {},
  mounted() {
    this.getResources()
  },
  methods: {
    async getResources() {
      let response = await Api.get('/content/resources', {
        responseType: 'json'
      })
      this.resources = response.data.data
    },
    async addResource() {
      this.sectionView = 'edit'

      if (!this.triggerCategories.length) {
        let response = await Api.get('/trigger/categories', {
          responseType: 'json'
        })
        this.triggerCategories = response.data.data
      }
    },

    async populateResource() {
      let vm = this
      this.populating = true
      let url = this.resource.url
      await Api.get('/content/resources/populate?url=' + url, {
        responseType: 'json'
      }).then(function(response) {
        vm.resource = response.data.data.info
        vm.resource.url = url
      })

      // this.resource = response.data.data.info
      this.populating = false
    },

    back() {
      this.sectionView = 'list'
    },
    async deleteResource(resourceId) {
      let vm = this

      await Api.delete('/content/resource?resource_id=' + resourceId)
        .then(function(response) {
          let idx = vm.resources.findIndex(function(r) {
            return r.resource_id === resourceId
          })

          vm.resources.splice(idx, 1)
        })
        .catch(function(error) {
          vm.error = error.response.data.message
          vm.saving = false
        })
    },
    async saveResource() {
      let vm = this
      vm.saving = true
      vm.error = null
      vm.resource.triggers = vm.selectedTriggers
      const response = await Api.post('/content/resources', vm.resource)
        .then(function(response) {
          // vm.resources = response.data.data
          vm.sectionView = 'list'
        })
        .catch(function(error) {
          vm.error = error.response.data.message
          vm.saving = false
        })
      vm.saving = false
      if (!vm.error) {
        vm.resources = response.data.data
      }
    }
  }
}
</script>
<style>
.v-btn {
  margin-right: 12px;
}
.text-field {
  width: 25%;
}
</style>
